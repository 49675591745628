import { state } from ":mods";
import { CoursesDescSectionProps } from "../../model";
import { createEffect } from "solid-js";
import { getSchedules } from "../..";

export default function DescriptionSection({ courses }: CoursesDescSectionProps) {
  const $coursesList = state.create([]);
  const $db_courses = state.createAsync(getSchedules);
  $db_courses.on((event, { value }) => {
    $coursesList.set(value.data);
  });

  const temp = [
    "Central Region - Online - 01/01/01 - 01/01/01",
    "Western Region - Online - 01/01/01 - 01/01/01",
    "Eastern Region - Online - 01/01/01 - 01/01/01",
    "Northern Region -  Online - 01/01/01 - 01/01/01",
    "Southern Region -  Online - 01/01/01 - 01/01/01",
  ];
  return (
    <section class="flex flex-col bg#paper items-center">
      <div class="flex flex-col gap-40px justify-center items-center my-80px">
        {$coursesList.value?.map(
          ({ id, image, course_title, topic_name, speaker_name, date, date_time, description, live, place }, index) => {
            const stringDate = new Date(date).toString().split(" ");
            date = `${stringDate[0]} ${stringDate[2]} ${stringDate[1]}, ${stringDate[3]}`;
            return (
              <div class="flex gap-22px">
                {!!image.src ? (
                  <img src={image.src} alt={image.alt} width={197} height={198} />
                ) : (
                  <div class="bg#p w-197px h-198px"></div>
                )}
                <article class="flex flex-col gap-10px w-1065px">
                  <p class="text-25px font-bold">
                    {course_title} {topic_name && `- ${topic_name}`}
                  </p>
                  {temp.map((item) => {
                    return <p class="text-16px leading-24px">{item}</p>;
                  })}
                  {/* <p class="text-16px leading-24px">{speaker_name}</p>
                  <p class="text-16px leading-24px font-bold">
                    {date_time} - {date} {place && `| ${place}`} {live && `| Live`}
                  </p>
                  <p class="text-16px leading-24px">{description}</p> */}
                </article>
              </div>
            );
          }
        )}
      </div>
      <hr class="max-w-screen w-screen h-2px bg#p" />
    </section>
  );
}
